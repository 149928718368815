<template>
  <div
    class="fixed bottom-0 left-0 w-full bg-neutral_500 h-[72px] flex items-center px-8"
    :class="competitionsToDisplayInNavigation.length ? 'justify-between' : 'justify-center gap-8'"
  >
    <NuxtLink
      v-for="(item, index) in menuItems"
      :key="index"
      :to="localePath(item.link)"
      class="flex items-center flex-col gap-1 max-w-[72px]"
      :class="item.applyOpacity ? 'opacity-30' : ''"
    >
      <div
        v-if="item.iconType == 'icon'"
        class="flex items-center justify-center px-4 py-[7px] rounded-full icon-holder"
      >
        <ClientOnly>
          <ion-icon class="text-primary_500" :class="item.size" :name="item.name" />
        </ClientOnly>
      </div>
      <img v-else :src="item.image" :class="item.size" alt="router link picture" />
      <p class="text-[12px] text-primary_500 font-medium leading-[150%] text-center">
        {{ item.text }}
      </p>
    </NuxtLink>
    <p
      v-if="competitionsToDisplayInDrawer.length"
      class="text-[12px] text-primary_500 font-medium leading-[150%] cursor-pointer px-4"
      @click="isDrawerOpened = true"
    >
      +{{ competitionsToDisplayInDrawer.length }}
    </p>
  </div>
  <ChallengesNavigationDrawer
    v-if="competitionsToDisplayInDrawer.length"
    :short-competitions="competitionsToDisplayInDrawer"
    :is-open="isDrawerOpened"
    @close-dialog="isDrawerOpened = false"
    @link-changed="handleLinkChanged"
  />
</template>
<script lang="ts" setup>
import type { MenuItem, ShortCompetition } from '~/interfaces';
import { shortChallengeRepository } from '~/repositories/shortChallengeRepository';
import professorsChallengeLogo from '~/assets/img/profChallengeNavLogo.png';

const MENU_COMPETITIONS_LIMIT = 2;

const { $api } = useNuxtApp();
const shortCompetitionRepo = shortChallengeRepository($api);

const route = useRoute();
const { t } = useI18n();
const localePath = useLocalePath();

const shortCompetitionId = route.params.id;

const isDrawerOpened = ref(false);
const activeShortCompetitions = ref<ShortCompetition[]>([]);
const competitionsToDisplayInNavigation = ref<ShortCompetition[]>([]);
const competitionsToDisplayInDrawer = ref<ShortCompetition[]>([]);

const menuItems = ref<MenuItem[]>([
  {
    name: 'flame-outline',
    size: 'text-[16px]',
    text: t('infoAndPromo'),
    link: '/professors',
    iconType: 'icon',
    hasSeparator: false
  },
  {
    name: 'challenge-landing',
    size: 'h-[30px]',
    text: t('professors'),
    link: '/professors-challenge',
    iconType: 'image',
    image: professorsChallengeLogo,
    hasSeparator: false
  }
]);

onBeforeMount(async () => {
  activeShortCompetitions.value = await shortCompetitionRepo.getActiveCompetitions();
  const expiredCompetition = (await shortCompetitionRepo.getExpiredShortCompetitions(1))[0];
  expiredCompetition.expired = true;
  activeShortCompetitions.value.push(expiredCompetition);

  if (activeShortCompetitions.value.length > MENU_COMPETITIONS_LIMIT) {
    let focusedCompetition = activeShortCompetitions.value[0]; // Ako ih ima vise od dva uzima prvi i njega prikazuje u navigaciji da bi se ostali otvorili na drawer

    if (shortCompetitionId) {
      // Ako ruta ima id onda se izvlaci to takmicenje i prikazuje u navigaciji ( ovo radi na mount komponente )
      const foundCompetitionIndex = activeShortCompetitions.value.findIndex(
        (c) => c.id === parseInt(shortCompetitionId as string)
      );
      const foundCompetition = activeShortCompetitions.value[foundCompetitionIndex];
      focusedCompetition = foundCompetition || focusedCompetition; // ovo je najvise zbog typescript-a, nemgouce je da izvuce id iz rute i da takmicenje ne postoji ali ajde
      activeShortCompetitions.value[foundCompetitionIndex] = activeShortCompetitions.value[0]; // prvi stavlja na mesto pronadjenog jer on ide u navigaciju a prvi u drawer
    }

    competitionsToDisplayInNavigation.value.push(focusedCompetition);
    competitionsToDisplayInDrawer.value = activeShortCompetitions.value.slice(1);
  } else {
    competitionsToDisplayInNavigation.value.push(...activeShortCompetitions.value); // ako ih ima do 2 onda se svi ispisuju u navigaciji i drawer ne postoji
  }
  // mapiranje potrebnih u navigacione linkove
  mapCompetitionsForNav();
});

// sluzi da izvuce link iz drawer-a u navigaciju, a ovaj iz navigacije stavi u drawer
const handleLinkChanged = (id: number) => {
  const indexOfChanged = competitionsToDisplayInDrawer.value.findIndex((x) => x.id == id);
  if (indexOfChanged >= 0) {
    const competitionToPutInNavigation = competitionsToDisplayInDrawer.value[indexOfChanged];
    competitionsToDisplayInDrawer.value[indexOfChanged] =
      competitionsToDisplayInNavigation.value[0];
    competitionsToDisplayInNavigation.value[0] = competitionToPutInNavigation;
    menuItems.value.splice(2, 1); // da se izvuce stari link iz menu item-a
    //ponovo izmapira takmicenje u link
    mapCompetitionsForNav();
  }
};

const mapCompetitionsForNav = () => {
  const mappedLinks = competitionsToDisplayInNavigation.value.map((competition) => ({
    name: competition.name,
    size: 'h-[30px]',
    text: competition.name,
    link: `/professors/short-challenge/${competition.id}`,
    hasSeparator: false,
    iconType: 'image',
    image: competition.iconUrl,
    applyOpacity: competition.expired ? true : false
  })) as MenuItem[];

  menuItems.value.push(...mappedLinks);
};
</script>
<style scoped>
.router-link-exact-active .icon-holder {
  background-color: #354d14;
}
.router-link-exact-active ion-icon {
  color: #fff;
}
.router-link-exact-active p {
  color: #fff;
}
</style>
