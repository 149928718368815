<template>
  <div class="flex items-center flex-col gap-4 px-2 py-8 rounded-full bg-primary_950">
    <NuxtLink
      v-for="(item, index) in navItems"
      :key="index"
      :to="localePath(item.link)"
      class="flex items-center flex-col gap-1 max-w-[72px] text-center"
      :class="item.applyOpacity ? 'opacity-30' : ''"
    >
      <div
        v-if="item.iconType == 'icon'"
        class="flex items-center justify-center px-4 py-[7px] rounded-full icon-holder"
      >
        <ClientOnly>
          <ion-icon class="text-primary_500" :class="item.size" :name="item.name" />
        </ClientOnly>
      </div>
      <img v-else :src="item.image" :class="item.size" alt="router link picture" />
      <p class="text-[12px] text-primary_500 font-medium leading-[150%]">{{ item.text }}</p>
    </NuxtLink>
  </div>
</template>

<script lang="ts" setup>
import type { MenuItem } from '~/interfaces';
import professorsChallengeLogo from '~/assets/img/profChallengeNavLogo.png';
import { shortChallengeRepository } from '~/repositories/shortChallengeRepository';

const { t, locale } = useI18n();
const localePath = useLocalePath();

const { $api } = useNuxtApp();
const shortCompetitionRepo = shortChallengeRepository($api);

const navItems = ref<MenuItem[]>([]); // Initialize navItems as an empty ref

// Static challenge menu items
const challengeMenuItems = ref<MenuItem[]>([
  {
    name: 'flame-outline',
    size: 'text-[16px]',
    text: t('infoAndPromo'),
    link: '/professors',
    hasSeparator: false,
    iconType: 'icon'
  },
  {
    name: 'challenge-landing',
    size: 'h-[36px]',
    text: t('professors'),
    link: `/professors-challenge`,
    hasSeparator: false,
    iconType: 'image',
    image: professorsChallengeLogo
  }
]);

// Fetch active competitions and add them to the nav
const createActiveShortCompetitionLinks = async () => {
  const activeCompetitions = await shortCompetitionRepo.getActiveCompetitions();
  const lastExpiredCompetition = (await shortCompetitionRepo.getExpiredShortCompetitions(1))[0];
  lastExpiredCompetition.expired = true;
  activeCompetitions.push(lastExpiredCompetition);
  const mappedLinks = activeCompetitions.map((competition) => ({
    name: competition.name,
    size: 'h-[30px]',
    text: competition.name,
    link: `/professors/short-challenge/${competition.id}`,
    hasSeparator: false,
    iconType: 'image',
    image: competition.iconUrl,
    applyOpacity: competition.expired ? true : false
  })) as MenuItem[];

  challengeMenuItems.value.push(...mappedLinks); // Update the challengeMenuItems

  // Update navItems with the combined menu items
  navItems.value = [...challengeMenuItems.value];
};

onBeforeMount(async () => {
  await createActiveShortCompetitionLinks();
});

watch(locale, () => {
  navItems.value = [...challengeMenuItems.value]; // Update navItems when locale changes
});
</script>

<style scoped>
.router-link-active .icon-holder {
  background-color: #354d14;
}
.router-link-active ion-icon {
  color: #fff;
}
.router-link-active p {
  color: #fff;
}
</style>
